import {Injectable} from "@angular/core";
import {LoggerService} from "@services/logger-service.service";
import {Observable, of} from "rxjs";
import {HttpClientAppService} from "@services/http-client-app.service";
import {ExceptionService} from "@services/exception.service";
import {LoaderService} from "@services/loader.service";
import { HttpParams } from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {News, News2, NewsRequest} from "@core/models/news.model";

@Injectable()
export class NewsService {
  PATH_CALENDAR_EVENTS: string = "/news";

  constructor(private logger: LoggerService,
              private httpClientApp: HttpClientAppService,
              private exception: ExceptionService,
              private loaderService: LoaderService) {
    this.logger.debug(this.constructor.name + " is created.");
  }

  /* @deprecated model News2 after old dashboard deleted from project */
  loadNews(request: NewsRequest): Observable<News[]> {
    let params: HttpParams = new HttpParams();
    if (request.startdate) params = params.set("startdate", request.startdate);
    if (request.startdate && request.enddate) params = params.set("enddate", request.enddate);
    if (request.category) params = params.set("category", request.category);
    return this.httpClientApp.get<News[]>(this.PATH_CALENDAR_EVENTS, params)
      .pipe(
        catchError(err => {
          this.exception.handleRequestErrorInfo(err);
          return of(null);
        })
      );
  }
}
