import {Injectable} from "@angular/core";
import {LoggerService} from "@services/logger-service.service";
import {Observable, of} from "rxjs";
import {HttpClientAppService} from "@services/http-client-app.service";
import {ExceptionService} from "@services/exception.service";
import { HttpParams } from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {Event} from "@core/models/event.model";

@Injectable()
export class EventsService {
  PATH_CALENDAR_EVENTS: string = "/AccCalendar/EventsOnDate";

  constructor(private logger: LoggerService,
              private httpClientApp: HttpClientAppService,
              private exception: ExceptionService) {
    this.logger.debug(this.constructor.name + " is created.");
  }

  loadEvents(startdate: string, enddate?: string): Observable<Event[]> {
    let params: HttpParams = new HttpParams();
    if (startdate) params = params.set("startdate", startdate);
    if (startdate && enddate) params = params.set("enddate", enddate);
    return this.httpClientApp.get<Event[]>(this.PATH_CALENDAR_EVENTS, params)
      .pipe(
        catchError(err => {
          this.exception.handleRequestErrorInfo(err);
          return of(null);
        })
      );
  }
}
