import { Injectable } from "@angular/core";
import { LoggerService } from "@services/logger-service.service";
import { Observable, of } from "rxjs";
import { HttpClientAppService } from "@services/http-client-app.service";
import { ExceptionService } from "@services/exception.service";
import { catchError, map } from "rxjs/operators";
import { CompanyFinalRating, CompanyRating } from "@core/models/company-rating.model";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class CompaniesService {
  PATH_FINALRATING_COMPANIES: string = "/finalraiting";
  PATH_RATING_COMPANIES: string = "/raiting";

  constructor(private logger: LoggerService,
              private httpClientApp: HttpClientAppService,
              private exception: ExceptionService) {
    this.logger.debug(this.constructor.name + " is created.");
  }

  loadRating(): Observable<CompanyRating[]> {
    return this.httpClientApp.get<CompanyRating[]>(this.PATH_RATING_COMPANIES)
      .pipe(
        catchError(err => {
          this.exception.handleRequestErrorInfo(err);
          return of(null);
        })
      );
  }

  loadCompanyRating(globalId: string): Observable<CompanyRating> {
    let params: HttpParams = new HttpParams();
    if (globalId) params = params.set("global_id", globalId);
    return this.httpClientApp.get<CompanyRating[]>(this.PATH_RATING_COMPANIES, params)
      .pipe(
        map((companyRating: CompanyRating[]) => companyRating[0]),
        catchError(err => {
          this.exception.handleRequestErrorInfo(err);
          return of(null);
        })
      );
  }

  loadFinalRating(): Observable<CompanyFinalRating[]> {
    return this.httpClientApp.get<CompanyFinalRating[]>(this.PATH_FINALRATING_COMPANIES)
      .pipe(
        catchError(err => {
          this.exception.handleRequestErrorInfo(err);
          return of(null);
        })
      );
  }
}
